// Here you can add other styles

// header styles
.img-account {
  background: #8080802b;
  padding: 5px;
}

.text-right {
  text-align: right !important;
}

.delete-icon,
.edit-icon {
  color: $primary-base !important;
  cursor: pointer;
}

.card {
  border-color: #f4f4f4;
  box-shadow: 0 6px 12px rgba(140, 152, 164, 0.075);

  .card-header {
    // padding: 1.3125rem 1.3125rem;
    margin-bottom: 0;
    background-color: #fff;
    border-bottom: 0.0625rem solid rgba(231, 234, 243, 0.7);
  }
}

//Dashabord
.dashboard-page-title {
  margin: 0;
}

.text-medium-emphasis-inverse {
  margin-bottom: 0 !important;
  svg {
    float: right;
  }
}

// Product managment pages
.product-img {
  .avatar-uploader {
    text-align: center;
  }

  .ant-upload.ant-upload-select {
    width: 200px !important;
    height: 125px !important;
  }

  .ant-upload-list-item-container {
    width: 200px !important;
    height: 125px !important;
  }
}

.product-varient-tabs {
  cursor: pointer;
}

.product-varient-add {
  vertical-align: sub;
  padding-left: 6px;
  text-decoration: none;
  cursor: pointer;
}

.product-options {
  background-color: #f0f0f06b;
}

// Order listing page
.order-datepicker {
  height: 38px;
}

.order-details {
  font-size: 14px;
  span {
    vertical-align: 2px;
  }
  label {
  }
}

.order-details.faded {
  color: $gray-600;
}

.light-gray {
  color: $gray-600;
}
.light-gray-bold {
  color: $gray-600;
  font-weight: bold;
}
.avatar-order {
  height: 60px !important;
  width: 60px !important;
}
.order-customer {
  text-align: start;
  font-size: 12px;

  .name {
    font-size: 14px;
    margin-bottom: 3px;
    span {
      vertical-align: baseline;
      margin-right: 8px;
    }
  }
  .name.bold {
    font-weight: bold;
  }
}

.price-item {
  font-size: 14px;
  margin-bottom: 3px;
  .text {
    font-weight: bold;
  }
  .value {
    color: $gray-600;
  }
}

// Employee Roles Page

.ant-checkbox-wrapper {
  padding: 3px;
  display: inline-flex;
  margin-top: 18px;
  margin-bottom: 10px;
}

.ant-checkbox-group {
  display: inline-block;
}

.ant-checkbox-wrapper + .ant-checkbox-wrapper {
  margin-inline-start: 0px !important;
}

//Customer steps page

.ant-steps.ant-steps-navigation .ant-steps-item::before {
  height: 5px;
  background-color: $primary;
}

.nav-icon > svg {
  flex: 0 0 var(--cui-sidebar-nav-icon-width);
  height: var(--cui-sidebar-nav-icon-height);
  font-size: var(--cui-sidebar-nav-icon-font-size);
  color: var(--cui-sidebar-nav-link-icon-color);
  text-align: center;
  pointer-events: none;
  fill: currentcolor;
  transition: inherit;
}

.message_style {
  margin-top: 50px;
}

html:not([dir='rtl']) .form-switch .form-check-input {
  margin-left: -2.5em;
  cursor: pointer;
}

.ant-steps-icon {
  display: flex;
  justify-content: center;
  align-items: center;
}

.ant-steps.ant-steps-navigation
  .ant-steps-item:not(:nth-last-child(2)):not(:nth-last-child(1)):after {
  position: absolute;
  top: -10%;
  inset-inline-start: 100%;
  display: inline-block;
  width: 1px;
  height: 49px;
  /* border-top: 1px solid rgba(0, 0, 0, 0.25); */
  /* border-bottom: 1px solid rgba(0, 0, 0, 0.25); */
  border-inline-start: 1px solid rgba(0, 0, 0, 0.25);
  border-inline-end: 1px solid rgba(0, 0, 0, 0.25);
  /* transform: translateY(-50%) translateX(-50%) rotate(45deg); */
  content: '';
  transform: rotate(180deg);
}

.ant-input-group .ant-input {
  padding: 0.375rem 0.75rem;
  width: 100%;
  margin-bottom: 0;
  text-align: inherit;
}

.nav-item:hover .nav-icon > svg {
  color: var(--cui-sidebar-nav-link-hover-icon-color);
}

// .ant-form-item-label > label.ant-form-item-required::before {
//   display: inline-block;
//   position: absolute;
//   top: 0;
//   right: 0;
//   // padding: 0 4px;
//   color: #ff4d4f; /* Customize the color of the star */
//   font-size: 14px;
//   line-height: 20px;
//   content: '*';
// }
.ant-input {
  box-sizing: border-box;
  margin: 0;
  // padding: 6px 11px;
  height: 40px;
  color: rgba(0, 0, 0, 0.88);
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5714285714285714;
  list-style: none;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
    'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
    'Noto Color Emoji';
  position: relative;
  display: inline-block;
  width: 100%;
  min-width: 0;
  background-color: #ffffff;
  background-image: none;
  border-width: 1px;
  border-style: solid;
  border-color: #d9d9d9;
  border-radius: 8px;
  transition: all 0.2s;
}

// .ant-form-item .ant-form-item-label > label {
//   position: relative;
//   display: inline-flex;
//   align-items: center;
//   max-width: 100%;
//   height: 32px;
//   color: rgba(0, 0, 0, 0.88);
//   font-size: 16px;
// }

.redText .ant-input {
  color: red !important;
}

.greenText .ant-input {
  color: green !important;
}

.test .ant-form-item {
  padding: 0;
}

.add-funds .ant-input-number-input {
  color: green !important;
}
.deduct-funds .ant-input-number-input {
  color: red !important;
}
